import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  TextField,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
} from "@mui/material";
import { IconAlertTriangle } from "@tabler/icons"; // Import the icon

function DataTable({ rows, columns }) {
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setPage(0); // Reset to first page on search
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page on rows per page change
  };

  const filteredRows = rows.filter((row) =>
    columns.some((column) => {
      const cellValue = row[column.field];
      return cellValue !== undefined && cellValue !== null
        ? cellValue.toString().toLowerCase().includes(searchQuery.toLowerCase())
        : false;
    })
  );

  const paginatedRows = filteredRows.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  // Add a new column for "Si No"
  const updatedColumns = [
    { field: "siNo", headerName: "Si No", width: 70 },
    ...columns,
  ];

  return (
    <>
      <Box textAlign="right">
        <TextField
          sx={{ width: { xs: "100%", sm: 300 }, my: 1 }}
          label="Search"
          variant="outlined"
          value={searchQuery}
          onChange={handleSearchChange}
          size="small"
        />
      </Box>
      <TableContainer>
        <Table>
          <TableHead sx={{ bgcolor: "#5d87ff" }}>
            <TableRow>
              {updatedColumns.map((column, index) => (
                <TableCell
                  sx={{
                    color: "#fff",
                    fontWeight: 600,
                    borderBottom: "1px solid #cecece",
                    textTransform: "capitalize",
                  }}
                  key={index}
                >
                  {column.headerName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedRows.length > 0 ? (
              paginatedRows.map((row, index) => (
                <TableRow key={index}>
                  {/* Add serial number cell */}
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      borderBottom: "1px solid #e2e2e2",
                    }}
                  >
                    {index + 1 + page * rowsPerPage}
                  </TableCell>
                  {updatedColumns.slice(1).map((column) => (
                    <TableCell
                      key={column.field}
                      sx={{
                        fontWeight: "bold",
                        borderBottom: "1px solid #e2e2e2",
                      }}
                    >
                      {column.renderCell
                        ? column.renderCell(row)
                        : row[column.field]}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={updatedColumns.length} align="center">
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    py={5}
                  >
                    <IconAlertTriangle size={48} color="text.secondary" />
                    <Typography variant="body1" color="text.secondary" mt={2}>
                      No data available
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {filteredRows.length > 0 && (
          <TablePagination
            component="div"
            count={filteredRows.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </TableContainer>
    </>
  );
}

DataTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      ItemNo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      headerName: PropTypes.string.isRequired,
      renderCell: PropTypes.func,
    })
  ).isRequired,
};

export default DataTable;
