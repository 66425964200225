const storedLgpath = localStorage.getItem("lgpath");
// const storedLgpath = urlHome ? decodeURIComponent(urlHome) : null;

// const baseUrl = "http://localhost:3008/laundry";
// const commonUrl = "http://localhost:3008/";
// const homeUrl = "http://localhost:5173/";
// const redirectUrl = storedLgpath
//   ? `http://localhost:5173/login?ur_d=${storedLgpath}`
//   : "http://localhost:5173/login";

// const baseUrl = "https://productionbackend.wizzo.in/laundry";
// const commonUrl = "https://productionbackend.wizzo.in/";
//  const homeUrl = 'https://productioncloud.wizzo.in/';
// const redirectUrl = storedLgpath
//   ? `https://productioncloud.wizzo.in/login?ur_d=${storedLgpath}`
//   : "https://productioncloud.wizzo.in/login";


 const baseUrl = "https://server.wizzo.in/laundry";
const commonUrl = "https://server.wizzo.in/";
 const homeUrl = "https://one.wizzo.in/";
const redirectUrl = storedLgpath
  ? `https://one.wizzo.in/login?ur_d=${storedLgpath}`
  : "https://one.wizzo.in/login";

// eslint-disable-next-line import/no-unused-modules
export { baseUrl, homeUrl, redirectUrl, commonUrl };
